import React, { useRef, useState } from 'react'
import { FieldInputProps } from 'react-final-form'
import { Collapse } from 'react-collapse'

import { DatePicker, Input } from 'components'
import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import { useOutsideClick } from 'hooks'
import style from './FieldDate.module.css'

type FieldDatePropType = {
    classes?: string
    classesField?: string
    date?: string
    name?: string
    placeholder?: string
    max?: string // date yyyy-mm-dd
    onChange: (value?: string) => void // date yyyy-mm-dd
} & FieldInputProps<string, HTMLElement>

const REGEXP_DATE = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.((19|20)\d{2}|\d{2})$/ // dd.mm.yy|yyyy
const DATE_MASK = '00.00.00[00]' // dd.mm.yy|yyyy
const DATE_OPTIONS: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: '2-digit' } // dd.mm.yy

export const FieldDate = ({
    classes,
    classesField,
    date,
    placeholder,
    max,
    onChange,
    ...inputProps
}: FieldDatePropType) => {
    const ref = useRef<HTMLInputElement>(null)
    const refInput = useRef<HTMLInputElement>(null)

    const [fieldValue, setFieldValue] = useState('')
    const [dateMax] = useState<Date | undefined>(max ? new Date(max) : undefined)
    const [dateCalendar] = useState<Date>(dateMax || new Date())
    const [dateSelected, setDateSelected] = useState<Date | undefined>(date ? new Date(date) : undefined)
    const [isOpenPicker, setIsOpenPicker] = useState(false)

    const handlerChangeDate = (el: HTMLInputElement) => {
        setFieldValue(el.value)
    }

    const handlerFocusDate = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsOpenPicker(true)
    }

    const handlerBlurDate = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!e.currentTarget.value) {
            resetDate()
        }
        if (e.currentTarget.value && REGEXP_DATE.test(e.currentTarget.value)) {
            setDate(e.currentTarget.value)
        }
    }

    const handlerKeyUpDate = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!e.currentTarget.value) {
                setIsOpenPicker(false)
                resetDate()
            }
            if (e.currentTarget.value && REGEXP_DATE.test(e.currentTarget.value)) {
                e.currentTarget.blur()
                setIsOpenPicker(false)
                setDate(e.currentTarget.value)
            }
        }
    }

    const handlerPickDate = (value: Date) => {
        const dateValue = dateTimeFormat(value, undefined, DATE_OPTIONS)

        setFieldValue(dateValue)
        setDate(dateValue)
        setIsOpenPicker(false)
    }

    /**
     * @param value - masked date dd.mm.yy|yyyy
     */
    function setDate(value: string) {
        const [day, month, year] = value.split('.')
        const newDate = new Date(`${month}-${day}-${year}`)

        setDateSelected(newDate)
        onChange(defaultDateFormat(newDate))
    }

    function resetDate() {
        setDateSelected(undefined)
        onChange(undefined)
    }

    useOutsideClick(ref, () => {
        setIsOpenPicker(false)
    })

    return (
        <div className={classes} ref={ref}>
            <Input
                {...inputProps}
                lazy
                classes={classesField}
                autoComplete="off"
                styleType="default"
                placeholder={placeholder}
                mask={DATE_MASK}
                value={fieldValue}
                ref={refInput}
                onFocus={handlerFocusDate}
                onBlur={handlerBlurDate}
                onKeyUp={handlerKeyUpDate}
                onAccept={handlerChangeDate}
            />
            <div className={style.collapse}>
                <Collapse isOpened={isOpenPicker}>
                    <DatePicker
                        isSetTime={false}
                        classes={style.datepicker}
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={handlerPickDate}
                    />
                </Collapse>
            </div>
        </div>
    )
}

export default FieldDate
