import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { APP_URL } from 'config/app'
import { RegistrationUserAction } from 'form-actions'
import { getURLSearchParams } from 'utils/helpers'
import { useAppSelector } from 'store'
import style from './Registration.module.css'

const Registration: React.FC = () => {
    const history = useHistory()

    const user = useAppSelector((state) => state.user)

    const [guarantorCode, setGuarantorCode] = useState<string>()

    useEffect(() => {
        const { id, invite } = getURLSearchParams(history.location.search)

        if (!user) {
            history.push(APP_URL.auth)
        }

        if (id || invite) {
            setGuarantorCode(id || invite)
        }
    }, [])

    return (
        <div className={style.registration}>
            <RegistrationUserAction
                classes={style.form}
                guarantorCode={guarantorCode}
            />
        </div>
    )
}

export default Registration
