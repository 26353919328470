import {
    IMarket,
    IShop,
    ICountry,
    IBasket,
    IBasketWallet,
    IDeliveryCost,
    IDeliveryAddress,
    IOrder,
    IStoreProfile,
    IStoreCustomers,
    IGoods,
} from 'interfaces'
import { TShopsProps } from 'services/MarketplaceService'
import {
    MARKET_WALLET_PAYOUT,
    MARKET_WALLET_BONUS,
} from 'config/app'
import { API_URL } from 'config/api'
import requestClient, { RequestOptions, RequestError } from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TMarketServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TRequestError<RequestParams> = RequestError<TMarketServiceError, RequestParams>

export type TShopsStaticCategoryProps = TShopsProps

export type TStoresProps = {
    offset?: number
    limit?: number
}

export type TStoreProfileProps = {
    id: number
}

export type TGoodsProps = {
    id: number
}

export type TAddGoodsProps = {
    name: string
    store: number
    price: number
    retailPrice: number
    availableStatus: number
    storeCatalog: {
        id: number
        name: string
        sort: number
    }[]
    isRecommended: 0|1
    isInventoryControl?: number
    subtitle?: string
    shortName?: string
    description?: string
    vendorCode?: string
    logo?: string // base64
    productType?: number
    quantityLimitForSale?: number
    quantityLimitForSaleGlobal?: number
    availableInMarketPlaceStatus?: number
    availabilityDateFrom?: string
    availabilityDateTo?: string
    weight?: number
    images?: {
        src: string
        format: number
        sort: number
    }[]
}

export type TUpdateGoodsProps = TAddGoodsProps & {
    id: number
    images?: {
        // id: number
        src: string
        format: number
        sort: number
        delete?: boolean
    }[]
}

export type TOrdersProps = {
    storeId: number
    offset?: number
    limit?: number
    statuses?: string // 2,100,...
}

export type TOrderProps = {
    storeId: number
    orderId: number
}

export type TAddOrderProps = {
    storeId: number
    delivery_method?: number
    delivery_address?: number
    buyer_account_id?: number
    payment_by_wallet?: boolean
    payment_by_payout?: boolean
    // 0 = virtual payment without order creation
    // 1 = payment by bank transaction in the application. Default.
    // 2 = payment by cash in a sales office
    // 3 = payment by terminal in a sales office
    // 4 = payment via bank account
    // 5 = postpay
    payment_service?: number
    products: {
        goods: number
        quantity: number
        payment_by?: string
    }[]
    pay_date: string
    random_id?: number
    comment?: string
}

export type TUpdateOrdersProps = TOrderProps

export interface TStoreCustomersProps {
    orderId: number
    limit?: number
    offset?: number
    period_in_days?: number
    is_friends_only?: boolean
    with_count?: boolean
}

export type TBasketProps = {
    storeId: number
}

export type TBasketUpdateProps = {
    storeId: number
    goods: number
    quantity?: number
    payment_by?: string
}

export type TBasketClearProps = {
    storeId: number
}

export type TBasketFromOrderProps = {
    storeId: number
    orderId: number
}

export type fetchDeliveryCountriesPropType = {
    storeId: number
}

export type fetchDeliveryCostPropType = {
    country: number
    city: number
    products: {
        goods: number
        quantity: number
        payment_by?: string
    }[]
}

export type fetchDeliveryAddressesPropType = {
    limit?: number
    offset?: number
}

export type addDeliveryAddressesPropType = {
    country: number
    city: number
    city_name: string
    full_name: string
    post_code: string
    address1?: string
    address2?: string
    street?: string
    home_number?: string
    room?: string
    phone?: string
    email?: string
}

export type updateDeliveryAddressesPropType = { id: number } & addDeliveryAddressesPropType

export type deleteDeliveryAddressesPropType = { id: number }

export type TBasketResponse = IBasket

export type TUpdateBasketResponse = IBasket

export type TClearBasketResponse = IBasket

export type TBasketFromOrderResponse = IBasket

export type TMarketCategoriesResponse = IMarket[]

export type TShopsStaticCategoryResponse = IShop[]

export type TGoodsResponse = IGoods[]

export type TAddGoodsResponse = IGoods

export type TUpdateGoodsResponse = IGoods

export type TOrdersResponse = IOrder[]

export type TOrderResponse = IOrder

type getOrderPaymentUrlPropType = {
    orderId: number
}

class MarketService {
    /**
     * Получить категории маркетплейса
     */
    static fetchMarketCategories(options?: RequestOptions) {
        return requestClient<TMarketCategoriesResponse>(API_URL.marketCategories, options)
    }

    /**
     * Получить магазины статической категории
     */
    static fetchShopsStaticCategory(params: TShopsStaticCategoryProps, options?: RequestOptions) {
        return requestClient<TShopsStaticCategoryResponse>(API_URL.store, { params, ...options })
    }

    static fetchStores({ offset, limit }: TStoresProps = {}) {
        return requestClient<IStoreProfile[]>(API_URL.store, { params: { offset, limit } })
    }

    /**
     * TODO compare StoreService.fetchStoreProfile
     */
    static fetchStoreProfile({ id }: TStoreProfileProps) {
        return requestClient<IStoreProfile>(`${API_URL.store}/${id}`)
    }

    static fetchGoods({ id }: TGoodsProps) {
        return requestClient<TGoodsResponse>(`${API_URL.goods}/${id}`)
    }

    static addGoods(params: TAddGoodsProps) {
        return requestClient<TAddGoodsResponse>(API_URL.goods, { method: 'post', data: params })
    }

    static updateGoods({ id, ...params }: TUpdateGoodsProps) {
        return requestClient<TUpdateGoodsResponse>(`${API_URL.goods}/${id}`, { method: 'put', data: params })
    }

    static fetchOrders(params: TOrdersProps) {
        const {
            storeId,
            offset = 0,
            limit = 10,
            ...options
        } = params
        const url = parseTpl(API_URL.orders, { store_id: storeId })

        return requestClient<TOrdersResponse>(url, { params: { offset, limit, ...options } })
    }

    static fetchOrder({ storeId, orderId }: TOrderProps) {
        const url = parseTpl(API_URL.orders, { store_id: storeId })
        return requestClient<TOrderResponse>(`${url}/${orderId}`)
    }

    static addOrder(params: TAddOrderProps) {
        const { storeId, ...options } = params
        const url = parseTpl(API_URL.orders, { store_id: storeId })

        return requestClient<IOrder>(url, { method: 'post', data: options })
    }

    static fetchOrderStoreCustomers(params: TStoreCustomersProps) {
        const { orderId, ...options } = params
        const url = parseTpl(API_URL.orderStoreCustomers, { order_id: orderId })

        return requestClient<IStoreCustomers>(url, { params: options })
    }

    static fetchBasket({ storeId }: TBasketProps) {
        const url = parseTpl(API_URL.basket, { store_id: storeId })
        return requestClient<TBasketResponse>(url)
    }

    static updateBasket({
        storeId,
        goods,
        quantity = 1,
        payment_by,
    }: TBasketUpdateProps) {
        const url = parseTpl(API_URL.basket, { store_id: storeId })
        return requestClient<TUpdateBasketResponse>(url, { method: 'post', data: { goods, quantity, payment_by } })
    }

    static clearBasket({ storeId }: TBasketClearProps) {
        const url = parseTpl(API_URL.basket, { store_id: storeId })
        return requestClient<TClearBasketResponse>(url, { method: 'delete' })
    }

    static getBasketFromOrder({ storeId, orderId }: TBasketFromOrderProps) {
        const url = parseTpl(API_URL.basketFromOrder, { store_id: storeId, order_id: orderId })
        return requestClient<TBasketFromOrderResponse>(url, { method: 'post' })
    }

    static fetchDeliveryCountries({ storeId }: fetchDeliveryCountriesPropType) {
        const url = parseTpl(API_URL.deliveryCountries, { store_id: storeId })
        return requestClient<ICountry[]>(url)
    }

    static fetchDeliveryCost(params: fetchDeliveryCostPropType) {
        return requestClient<IDeliveryCost[]>(API_URL.deliveryCost, { method: 'post', data: params })
    }

    static fetchDeliveryAddresses(params?: fetchDeliveryAddressesPropType) {
        return requestClient<IDeliveryAddress[]>(API_URL.deliveryAddresses, { params })
    }

    static addDeliveryAddresses(params: addDeliveryAddressesPropType) {
        return requestClient<IDeliveryAddress>(API_URL.deliveryAddresses, { method: 'post', data: params })
    }

    static updateDeliveryAddresses(params: updateDeliveryAddressesPropType) {
        const { id, ...data } = params
        return requestClient<IDeliveryAddress>(`${API_URL.deliveryAddresses}/${id}`, { method: 'put', data })
    }

    static deleteDeliveryAddresses(params: deleteDeliveryAddressesPropType) {
        const { id } = params
        return requestClient<boolean>(`${API_URL.deliveryAddresses}/${id}`, { method: 'delete' })
            .then(() => true)
    }

    static getOrderPaymentUrl({ orderId }: getOrderPaymentUrlPropType) {
        const url = parseTpl(API_URL.paymentUrl, { order_id: orderId })
        return requestClient<any>(url, { method: 'patch' })
    }

    /**
     * Расчет суммы доступных вознаграждений
     */
    static getPayoutAvailableSum(wallets?: IBasketWallet[]): number {
        const wallet = wallets?.find((item) => item.applying === MARKET_WALLET_PAYOUT)

        if (wallet) {
            const { amount, basket_use } = wallet
            return !Number.isNaN(amount) && !Number.isNaN(basket_use) ? amount - basket_use : 0
        }

        return 0
    }

    /**
     * Расчет суммы доступных бонусов
     */
    static getBonusAvailableSum(wallets?: IBasketWallet[]): number {
        const walletsBonus = wallets?.filter((item) => item.applying === MARKET_WALLET_BONUS)

        if (walletsBonus) {
            return walletsBonus.reduce((acc, item) => {
                const { amount, locked_funds, rate_to_store_currency } = item

                if (!Number.isNaN(amount) && !Number.isNaN(locked_funds) && !Number.isNaN(rate_to_store_currency)) {
                    return acc + (amount - locked_funds) * rate_to_store_currency
                }

                return acc
            }, 0)
        }

        return 0
    }

    /**
     * Получить валюту вознаграждения
     */
    static getPayoutCurrency(wallets?: IBasketWallet[]): string {
        const wallet = wallets?.find((item) => item.applying === MARKET_WALLET_PAYOUT)
        return wallet?.code ? wallet.code : ''
    }
}

export default MarketService
