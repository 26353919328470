import React, { useEffect, useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder } from 'interfaces'
import {
    APP_URL,
    MARKET_ORDER_STATUS_READY_PAYMENT,
    MARKET_ORDER_STATUS_PAID,
} from 'config/app'
import { Block } from 'layout'
import { useFetchPaymentStatuses } from 'containers/App/hooks'
import {
    InfiniteScroll,
    Loader,
    Checkbox,
    Popover,
    SvgResource,
    ErrorMsg,
} from 'components'
import { showAlertNotify, getRequestError, scrollTop } from 'utils/helpers'
import { MarketService } from 'services'
import { StoreOrdersList } from './components'
import { LIST_COLUMNS } from './components/StoreOrdersList/StoreOrdersList'
import { useFetchStoreOrders } from './hooks'
import style from './StoreOrders.module.css'

const ORDERS_LIMIT = 20
const ORDERS_OFFSET = 0

const StoreOrders: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const history = useHistory()

    const [storeId] = useState(Number(id))
    const [isSetStoreId] = useState(!Number.isNaN(storeId))
    const [orders, setOrders] = useState<IOrder[]>([])
    const [offset, setOffset] = useState(ORDERS_OFFSET)
    const [listColumns, setListColumns] = useState(LIST_COLUMNS)

    const [isLoadingPaymentUrl, setIsLoadingPaymentUrl] = useState(false)
    const [isHideLoader, setIsHideLoader] = useState(false)
    const [isOpenListSettings, setIsOpenListSettings] = useState(false)

    const {
        isInitialLoading: isLoadingStoreOrders,
        data: dataOrders,
        error: errorOrders,
    } = useFetchStoreOrders({
        storeId,
        statuses: `${MARKET_ORDER_STATUS_READY_PAYMENT},${MARKET_ORDER_STATUS_PAID}`,
        limit: ORDERS_LIMIT,
        offset,
    }, {
        enabled: isSetStoreId,
        staleTime: 30 * 1000, // 30s
        cacheTime: 30 * 1000, // 30s
    })

    const { data: dataPaymentStatuses } = useFetchPaymentStatuses()

    const handlerClickPayment = (orderId: number) => {
        getOrderPaymentUrlAction(orderId)
    }

    const handlerOnLoad = () => {
        setOffset((prevState) => prevState + ORDERS_LIMIT)
    }

    const handlerChangeListActiveColumn = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        setListColumns((prevState) => prevState.map((item) => {
            return item.key === currentTarget.name ? { ...item, checked: currentTarget.checked } : item
        }))
    }

    function getOrderPaymentUrlAction(orderId: number) {
        setIsLoadingPaymentUrl(true)
        MarketService.getOrderPaymentUrl({ orderId })
            .then(({ data }) => {
                if (data) {
                    history.push(generatePath(APP_URL.orderPayment, { id: storeId, orderId }))
                } else {
                    showAlertNotify({ type: 'error', message: t('update_error') })
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
            .finally(() => {
                setIsLoadingPaymentUrl(false)
            })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (!isSetStoreId) {
            setIsHideLoader(true)
        }
    }, [isSetStoreId])

    useEffect(() => {
        if (dataOrders) {
            const ordersLength = dataOrders.length

            if (ordersLength < ORDERS_LIMIT) {
                setIsHideLoader(true)
            }

            setOrders((prevState) => ([...prevState, ...dataOrders]))
        }
    }, [dataOrders])

    useEffect(() => {
        if (errorOrders) {
            setIsHideLoader(true)
        }
    }, [errorOrders])

    return (
        <div className={style.content}>
            {isLoadingStoreOrders && (
                <Loader />
            )}

            {!isLoadingStoreOrders && !orders.length && !errorOrders && isSetStoreId && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {!isLoadingStoreOrders && errorOrders && (
                <ErrorMsg error={errorOrders[0]} />
            )}

            {!!orders.length && (
                <Block classes={cn(style.block, { [style.updating]: isLoadingPaymentUrl })}>
                    <Block.Header classes={style.blockHeader}>
                        <Block.HeaderInner classes={style.blockHeaderInner}>
                            <div className={style.blockTitle}>
                                {t('order_list')}
                            </div>
                            <div className={style.blockActions}>
                                <Popover
                                    classes={cn(style.blockAction, style.blockActionSettings)}
                                    classesBody={style.listSettings}
                                    isOpen={isOpenListSettings}
                                    isShowArrow={false}
                                    isAutoClose={false}
                                    trigger={(
                                        <SvgResource
                                            resourceKey="ic_list_svg"
                                            width={18}
                                            height={14}
                                        />
                                    )}
                                    position="bottom"
                                    side="left"
                                    isDisabled={!dataOrders}
                                    onOpen={() => setIsOpenListSettings(true)}
                                    onClose={() => setIsOpenListSettings(false)}
                                >
                                    {listColumns.map((item) => (
                                        <div className={style.listSettingsItem} key={item.key}>
                                            <Checkbox
                                                styleType="small"
                                                name={item.key}
                                                text={t(item.translateKey)}
                                                checked={item.checked}
                                                onChange={handlerChangeListActiveColumn}
                                            />
                                        </div>
                                    ))}
                                </Popover>
                            </div>
                        </Block.HeaderInner>
                    </Block.Header>
                    <Block.BodyCollapse
                        isOpen
                        classesContent={cn(style.blockBody, style.blockBody_list)}
                    >
                        <InfiniteScroll
                            isHideLoader={isHideLoader}
                            onLoad={handlerOnLoad}
                        >
                            <StoreOrdersList
                                data={orders}
                                columns={listColumns}
                                paymentStatuses={dataPaymentStatuses}
                                onPayment={handlerClickPayment}
                            />
                        </InfiniteScroll>
                    </Block.BodyCollapse>
                </Block>
            )}
        </div>
    )
}

export default StoreOrders
