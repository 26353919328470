import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TOrdersProps,
    TOrdersResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TOrdersProps
export type TQueryFnOpts = UseQueryOptions<
    TOrdersResponse,
    [string, TRequestError<TFetchFnParams>],
    TOrdersResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.storeOrders

/**
 * Hook API store user orders list
 */
export default function useFetchStoreOrders(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return MarketService.fetchOrders(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
