import React from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IPaymentStatuses } from 'interfaces'
import { APP_URL, MARKET_ORDER_STATUS_READY_PAYMENT } from 'config/app'
import {
    Button,
    Link,
    TableList,
    NoDataInfo,
} from 'components'
import { dateTimeFormat, numberFormat } from 'utils/helpers'
import style from './StoreOrdersList.module.css'

export enum ColumnKey {
    id = 'id',
    items = 'items',
    fio = 'fio',
    promocode = 'promocode',
    order_sum = 'order_sum',
    create_date = 'create_date',
    payment_date = 'payment_date',
    bonus = 'bonus',
    payment_status = 'payment_status',
    delivery = 'delivery',
    comment = 'comment',
}

export type TColumn = {
    key: keyof typeof ColumnKey
    translateKey: string
    checked: boolean
}

type StoreOrdersListPropType = {
    classes?: string
    columns?: TColumn[]
    data?: IOrder[]
    paymentStatuses?: IPaymentStatuses
    onPayment: (id: number) => void
}

export const LIST_COLUMNS: TColumn[] = [
    { key: ColumnKey.id, translateKey: 'order_id', checked: true },
    { key: ColumnKey.items, translateKey: 'Items in the order', checked: false },
    { key: ColumnKey.fio, translateKey: 'full_name', checked: true },
    { key: ColumnKey.promocode, translateKey: 'Promocode', checked: true },
    { key: ColumnKey.order_sum, translateKey: 'order_sum', checked: true },
    { key: ColumnKey.create_date, translateKey: 'order_date_create', checked: true },
    { key: ColumnKey.payment_date, translateKey: 'order_date_time_payment', checked: true },
    { key: ColumnKey.bonus, translateKey: 'order_bonus', checked: true },
    { key: ColumnKey.payment_status, translateKey: 'order_payment_status', checked: true },
    { key: ColumnKey.delivery, translateKey: 'delivery', checked: false },
    { key: ColumnKey.comment, translateKey: 'comment', checked: true },
]

const ORDER_STATUS_SHOW = [20, 30, 40, 50, 60]
const ORDER_DELIVERY_STATUS_EXCLUDE = [100]

const StoreOrdersList: React.FC<StoreOrdersListPropType> = ({
    classes,
    columns = LIST_COLUMNS,
    data,
    paymentStatuses,
    onPayment,
}) => {
    const { t, i18n } = useTranslation()

    const getFormattedValue = (key: keyof typeof ColumnKey, values: IOrder) => {
        switch (key) {
            case 'items':
                return (
                    <div>
                        {values.content.map((item, index) => (
                            <span
                                className={cn(
                                    style.colText,
                                    style.colText_noWrap,
                                    style.colText_row,
                                    style.colText_marker,
                                )}
                                key={item.goods.id}
                            >
                                <Link
                                    className={cn(style.link, style.link_blue)}
                                    url={generatePath(APP_URL.goods, { id: item.goods.id })}
                                >
                                    {item.goods.name}
                                </Link>
                                {` (${item.quantity})`}
                            </span>
                        ))}
                    </div>
                )
            case 'fio':
                return values.ordered_for?.fio
            case 'promocode':
                return (
                    <span className={cn(style.colText, style.colText_noWrap)}>
                        {values.ordered_for && values.ordered_for.member_id}
                    </span>
                )
            case 'order_sum':
                return (
                    <span className={cn(style.colText, style.colText_noWrap)}>
                        {`${numberFormat(values.order_sum)} ${values.store.currency}`}
                    </span>
                )
            case 'create_date':
                return dateTimeFormat(values.create_date, i18n.language, { dateStyle: 'short', timeStyle: 'medium' })
            case 'payment_date':
                return values.payment_date
                    && dateTimeFormat(values.payment_date, i18n.language, { dateStyle: 'short', timeStyle: 'medium' })
            case 'payment_status':
                return paymentStatuses ? paymentStatuses[values.payment_status] : values.payment_status
            case 'delivery': {
                const {
                    market_order_status_id: orderStatusId,
                    market_order_status_name: orderStatusName,
                    track_info: trackInfo,
                } = values

                return (
                    <div>
                        {!!orderStatusId && ORDER_STATUS_SHOW.includes(orderStatusId) && (
                            <div className={cn(style.status, style[`status_${orderStatusId}`])}>
                                {orderStatusName}
                            </div>
                        )}
                        {trackInfo?.map((item) => (
                            !ORDER_DELIVERY_STATUS_EXCLUDE.includes(item.status) && item.track_number && (
                                <div className={cn(style.status, style.status_default)} key={item.track_number}>
                                    {`${item.track_number} - ${item.message}`}
                                </div>
                            )
                        ))}
                    </div>
                )
            }
            default:
                return values[key]
        }
    }

    return (
        <>
            {data && !data.length && (
                <NoDataInfo classes={style.noDataText} text={t('no_data')} />
            )}
            {data && !!data.length && (
                <TableList classes={cn(style.list, classes)}>
                    <TableList.Header classes={style.header}>
                        {columns.map((item) => (
                            item.checked && (
                                <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                                    <div className={style.colInner}>
                                        <div className={style.headerTitle}>
                                            {t(item.translateKey)}
                                        </div>
                                    </div>
                                </TableList.Col>
                            )
                        ))}
                        <TableList.Col classes={cn(style.col, style.col_header)}>
                            {t('actions_title')}
                        </TableList.Col>
                    </TableList.Header>
                    <TableList.Body>
                        {data.map((item) => (
                            <TableList.Row classes={style.row} key={item.id}>
                                {columns.map((itemColumn) => (
                                    itemColumn.checked && (
                                        <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                            <div className={style.colTitle}>
                                                {t(itemColumn.translateKey)}
                                            </div>
                                            <div className={style.colInner}>
                                                {getFormattedValue(itemColumn.key, item)}
                                            </div>
                                        </TableList.Col>
                                    )
                                ))}
                                <TableList.Col classes={cn(style.col, style.col_body)}>
                                    <div className={style.colTitle}>
                                        {t('actions_title')}
                                    </div>
                                    <div className={style.colInner}>
                                        {item.payment_status === MARKET_ORDER_STATUS_READY_PAYMENT && (
                                            <Button
                                                classes={cn(style.text, style.text_action, style.link)}
                                                styleType="text"
                                                text={t('market_order_purchase_button')}
                                                onClick={() => onPayment(item.id)}
                                            />
                                        )}
                                        {item.invoice_link && (
                                            <a
                                                className={cn(style.text, style.text_action, style.link)}
                                                href={item.invoice_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('item_market_order_invoice_url_title')}
                                            </a>
                                        )}
                                    </div>
                                </TableList.Col>
                            </TableList.Row>
                        ))}
                    </TableList.Body>
                </TableList>
            )}
        </>
    )
}

export default StoreOrdersList
