import React, { useMemo } from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    ICalendarPostProps,
    IPostContent,
    IPostContentAttacheParams,
    ICommunity,
    ICalendarHour,
} from 'interfaces'
import { TAddCalendarPostProps, TUpdateCalendarPostProps } from 'services/CommunityService'
import { POST_TYPE_EVENT } from 'config/app'
import {
    Button,
    Input,
    Textarea,
    CustomSelect,
    Post,
} from 'components'
import { defaultDateFormat, getId } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './CalendarPostForm.module.css'
import { FieldDateTime, AttachControls } from './components'

export enum FieldName {
    community = 'community',
    title = 'title',
    text = 'text',
    dateStart = 'calendarDateFrom',
    dateEnd = 'calendarDateTo',
    files = 'files',
}

export type FormDataType = TAddCalendarPostProps | TUpdateCalendarPostProps

type CalendarPostFormPropType = {
    isSubmitting: boolean
    files: ICalendarPostProps['files']
    content: IPostContent[]
    communities?: ICommunity[]
    onAddFiles: (files: FileList, params: IPostContentAttacheParams) => void
    onAddGoods: (params: IPostContentAttacheParams) => void
    onAddImage: (params: IPostContentAttacheParams) => void
    onChangeContentDesc: (id: number, value: string) => void
    onDeletePostFile: (id: number) => void
    onDeleteContentFile: (id: number) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const TIME_INTERVALS = [0, 0.25, 0.5, 0.75]
const TIME_MULTIPLIERS = [1.5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

export const formDataInitial: FormDataType = {
    type: POST_TYPE_EVENT,
    community: 0,
    title: '',
    text: '',
    calendarDateFrom: '',
    calendarDateTo: '',
    isPublished: true,
    isShowAuthor: true,
    files: [],
}

/**
 * Get cyclically repeating time intervals
 * @example
 * 12:00
 * 12:15
 * 12:30
 * 12:45
 * 13:00
 * 13:15
 * ...
 */
const getTimeIntervalList = (date: Date, intervals: number[], timeStart: number = 0): ICalendarHour[] => {
    if (date) {
        const dateCurrent = new Date()
        const dateYear = date.getFullYear()
        const dateMonth = date.getMonth()
        const dateDay = date.getDate()
        const dateHours = date.getHours()

        const isToday = defaultDateFormat(dateCurrent) === defaultDateFormat(date)
        const itemsLength = isToday ? 23 - dateHours : 24

        return [...Array<ICalendarHour>(itemsLength)]
            .reduce<ICalendarHour[]>((acc, item, index) => {
                const hours = isToday ? dateHours + index + 1 : index

                intervals.forEach((value) => {
                    const minutes = (hours * 60) + (60 * value)
                    const timeDate = new Date(dateYear, dateMonth, dateDay, Math.floor(minutes / 60), minutes % 60)

                    acc.push({
                        id: `${index + value}`,
                        date: new Date(timeDate),
                    })
                })

                return acc
            }, [])
    }
    return []
}

/**
 * Get time intervals by multiplier
 * @example
 * 12:30
 * 13:00
 * 14:00
 * ...
 */
const getDayHoursMultiplierList = (date: Date, multiplier: number[] = [0], hourStart: number = 0): ICalendarHour[] => {
    if (date) {
        const dateCurrent = new Date()
        const dateYear = date.getFullYear()
        const dateMonth = date.getMonth()
        const dateDay = date.getDate()
        const dateHours = date.getHours()
        const isToday = defaultDateFormat(dateCurrent) === defaultDateFormat(date)

        if (isToday) {
            return multiplier
                .reduce<ICalendarHour[]>((acc, item, index) => {
                    const hours = dateHours + index
                    const minutes = (hours * 60) + (item * 60)
                    const timeDate = new Date(dateYear, dateMonth, dateDay, Math.floor(minutes / 60), minutes % 60)

                    return timeDate.getDate() === dateDay && timeDate.getHours() <= 23
                        ? [...acc, { id: String(index), date: timeDate }]
                        : acc
                }, [])
        }

        return [...Array<ICalendarHour>(24 - hourStart)]
            .map((item, index) => {
                const hours = hourStart + index
                const timeDate = new Date(dateYear, dateMonth, dateDay, hours, 0)

                return {
                    id: String(index),
                    date: timeDate,
                }
            })
    }
    return []
}

const CalendarPostForm: React.FC<CalendarPostFormPropType> = ({
    isSubmitting,
    files,
    content,
    communities,
    onAddFiles,
    onAddGoods,
    onAddImage,
    onChangeContentDesc,
    onDeletePostFile,
    onDeleteContentFile,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const mainFiles = useMemo(() => {
        return files.filter((item) => ('delete' in item ? !item.delete : true))
    }, [files])

    const communitiesData = useMemo(() => {
        return communities
            ? communities.reduce((acc, item) => ({ ...acc, [item.community.id]: item.community.title }), {})
            : {}
    }, [communities])

    const handlerAddFiles = (fileList: FileList, contentId?: number) => {
        if (fileList) {
            onAddFiles(fileList, { isAttach: !!contentId, contentId })
        }
    }

    const handlerAddGoods = (contentId?: number) => {
        onAddGoods({ isAttach: !!contentId, contentId })
    }

    const handlerAddImage = (contentId?: number) => {
        onAddImage({ isAttach: !!contentId, contentId })
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_12, styleForm.row_vAlignCenter)}>
                <div className={style.rowText}>
                    {t('ui_posts_community_hint')}
                </div>
                <Field
                    <FormDataType[FieldName.community]>
                    name={FieldName.community}
                    render={({ input }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field
                                classes={cn(
                                    style.fieldCommunity,
                                    { [style.fieldCommunity_disabled]: !communities || communities.length === 1 },
                                )}
                                disabled={!communities || communities.length === 1}
                            >
                                {!!input.value && communitiesData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {communities?.map((item) => (
                                    <CustomSelect.Option
                                        id={item.community.id}
                                        isActive={item.community.id === input.value}
                                        key={item.community.id}
                                        onClick={input.onChange}
                                    >
                                        {item.community.title}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_12, styleForm.row_24)}>
                <div className={style.rowText}>
                    {t('calendar_date_select')}
                </div>
                <div className={cn(styleForm.row, style.subRow)}>
                    <div className={style.subRowText}>
                        {`${t('calendar_event_start')}*`}
                    </div>
                    <FieldDateTime
                        classes={style.fieldDateTime}
                        name={FieldName.dateStart}
                        timeList={(dateTime) => getTimeIntervalList(dateTime, TIME_INTERVALS)}
                    />
                </div>
                <div className={cn(styleForm.row, style.subRow)}>
                    <div className={style.subRowText}>
                        {`${t('calendar_event_ending')}*`}
                    </div>
                    <FieldDateTime
                        classes={style.fieldDateTime}
                        name={FieldName.dateEnd}
                        timeList={(dateTime) => getDayHoursMultiplierList(dateTime, TIME_MULTIPLIERS)}
                    />
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_24, styleForm.row_vAlignCenter)}>
                <div className={style.rowText}>
                    {t('add_attach')}
                </div>
                <AttachControls
                    classes={cn(style.controls, style.controls_alignRight)}
                    onAddFiles={handlerAddFiles}
                    onAddGoods={handlerAddGoods}
                    onAddImage={handlerAddImage}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Post.MainImage
                    files={mainFiles}
                    onDeleteFile={onDeletePostFile}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.title}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('ui_posts_post_title_hint')}*`}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <Field
                        name={FieldName.text}
                        render={({ input, meta }) => (
                            <>
                                <Textarea
                                    {...input}
                                    classes={cn(style.textarea, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('ui_posts_post_text_hint')}*`}
                                    limit={10000}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            {content.map((item, index) => (
                /* skip post main text item */
                index > 0 && (
                    <div className={cn(styleForm.row, styleForm.row_24)} key={item.id}>
                        <Post.Content
                            content={item}
                            onClickDeleteContentFile={onDeleteContentFile}
                        >
                            <AttachControls
                                classes={cn(style.controls, style.controls_contentRight, style.controls_mt)}
                                controlId={item.id}
                                onAddFiles={handlerAddFiles}
                                onAddGoods={handlerAddGoods}
                                onAddImage={handlerAddImage}
                            />
                            <Post.ContentTextArea
                                data={item}
                                onChange={onChangeContentDesc}
                            />
                        </Post.Content>
                    </div>
                )
            ))}
            {!!mainFiles.length && (
                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <AttachControls
                        classes={cn(style.controls, style.controls_contentRight)}
                        controlId={getId(true)}
                        onAddFiles={handlerAddFiles}
                        onAddGoods={handlerAddGoods}
                        onAddImage={handlerAddImage}
                    />
                </div>
            )}
            <div className={style.actions}>
                <Button
                    textUpper
                    type="submit"
                    size="size40"
                    text={'id' in values ? t('save') : t('ui_posts_send_form_button_text')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default CalendarPostForm
