import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { REGEXP_EMAIL } from 'config/app'
import {
    IUser,
    IUserUpdateProps,
    IAgreementCompany,
    IAgreement,
    ICompanyAgreementData,
} from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/RegistrationUserForm/RegistrationUserForm'
import { Modal, Button, CompanyAgreements } from 'components'
import { useThunkDispatch } from 'hooks'
import {
    AppService,
    AuthService,
    UserService,
    AgreementService,
} from 'services'
import {
    isObject,
    getRequestError,
    showAlertNotify,
    defaultDateFormat,
} from 'utils/helpers'
import { updateUser } from 'containers/User/user-actions'
import { RegistrationUserForm } from 'forms'
import { useAppSelector } from 'store'

export type RegistrationUserActionPropType = {
    classes?: string
    guarantorCode?: string
}

const RegistrationUserAction: React.FC<RegistrationUserActionPropType> = ({ classes, guarantorCode }) => {
    const { t } = useTranslation()
    const isMounted = useRef(false)
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()

    const user = useAppSelector((state) => state.user)
    const countries = useAppSelector((state) => state.app.countries)

    const [paramsProfileUser, setParamsProfileUser] = useState<IUserUpdateProps>()
    const [regDataUser] = useState(UserService.getRegData())

    const [initialValues, setInitialValues] = useState<FormDataType>({
        ...formDataInitial,
        ...regDataUser,
    })

    const [companiesAgreementList, setCompaniesAgreementList] = useState<null | IAgreementCompany[]>(null)
    const [companyAgreement, setCompanyAgreement] = useState<null | {
        company: IAgreementCompany
        agreement: IAgreement
    }>(null)
    const [confirmedCompanyAgreement, setConfirmedCompanyAgreement] = useState<null | ICompanyAgreementData>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isShowModalAgreements, setIsShowModalAgreements] = useState(false)
    const [isDisabledConfirmAgreement, setIsDisabledConfirmAgreement] = useState(true)

    const maxBirthDayRestrictions = useMemo(() => {
        const date = AppService.getDateMinimumAgeRegistration()
        return defaultDateFormat(date)
    }, [])

    const handlerSubmit = ({ [FieldName.guarantor_code]: code, ...params }: FormDataType) => {
        if (code) {
            setParamsProfileUser(params)
            setGuarantorAction(code)
        } else {
            updateUserAction(params)
        }
    }

    const handlerChange = ({ city, ...params }: FormDataType) => {
        /**
         * Fix: dont save city, no api method get city by id later
         */
        UserService.saveRegData(params)
    }

    const handlerChangeCompanyAgreements = (data: ICompanyAgreementData) => {
        if (data.success) {
            setConfirmedCompanyAgreement(data)
            setIsDisabledConfirmAgreement(false)
        } else {
            setConfirmedCompanyAgreement(null)
            setIsDisabledConfirmAgreement(true)
        }
    }

    const handlerConfirmAgreement = () => {
        setIsShowModalAgreements(false)

        if (confirmedCompanyAgreement) {
            setAgreementSignedAction(confirmedCompanyAgreement)
        }
    }

    function resetGuarantorCode() {
        const regData = UserService.getRegData()

        if (regData) {
            const { [FieldName.guarantor_code]: code, ...params } = regData
            UserService.saveRegData(params)
        }
    }

    function setGuarantorAction(code: string) {
        setIsSubmitting(true)
        UserService.setGuarantor({ guarantor_code: code })
            .then(({ data }) => {
                if (data?.status === 'success') {
                    getCompanyAgreementAction()
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)

                resetGuarantorCode()
                showAlertNotify({ type: 'error', message: errorText })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function getCompanyAgreementAction() {
        setIsSubmitting(true)
        AgreementService.getAgreement()
            .then(({ data }) => {
                if (data?.length) {
                    setCompaniesAgreementList(data)
                } else if (paramsProfileUser) {
                    updateUserAction(paramsProfileUser)
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)

                resetGuarantorCode()
                showAlertNotify({ type: 'error', message: errorText })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function setAgreementSignedAction({ companyId, agreementId }: ICompanyAgreementData) {
        setIsSubmitting(true)
        AgreementService.setAgreementSigned(agreementId)
            .then(({ data }) => {
                if (data?.success) {
                    /**
                     * Find company and mark company agreement signed = true
                     */
                    setCompaniesAgreementList(((prevState) => {
                        return !prevState ? null : prevState.map((company) => {
                            if (company.company_id === companyId) {
                                const agreements = company.agreements.map((agr) => {
                                    return agr.id === agreementId ? { ...agr, signed: true } : agr
                                })

                                return { ...company, agreements }
                            }

                            return company
                        })
                    }))
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)
                showAlertNotify({ type: 'error', message: errorText })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    /**
     * Create user profile
     */
    function updateUserAction(userProps: IUserUpdateProps) {
        setIsSubmitting(true)
        thunkDispatch(updateUser(userProps))
            .then((updatedUser) => {
                UserService.clearRegData()
                authorizeAction(updatedUser)
            })
            .catch((err) => {
                let errorText = getRequestError(err)

                if (!errorText && isObject(err.response.data)) {
                    const { name, surname, email } = err.response.data
                    let formErrorText = ''

                    if (Array.isArray(name) && typeof name[0] === 'string') {
                        const [msg] = name
                        formErrorText = msg
                    } else if (typeof name === 'string') {
                        formErrorText = name
                    } else if (Array.isArray(surname) && typeof surname[0] === 'string') {
                        const [msg] = surname
                        formErrorText = msg
                    } else if (typeof surname === 'string') {
                        formErrorText = surname
                    } else if (Array.isArray(email) && typeof email[0] === 'string') {
                        const [msg] = email
                        formErrorText = msg
                    } else if (typeof email === 'string') {
                        formErrorText = email
                    }

                    errorText = formErrorText
                }

                showAlertNotify({ type: 'error', message: errorText })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function authorizeAction(userData: IUser) {
        setIsSubmitting(true)
        AuthService.authorize(userData, location)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)

                showAlertNotify({ type: 'error', message: errorText })
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (guarantorCode) {
            setInitialValues((prevState) => ({ ...prevState, [FieldName.guarantor_code]: guarantorCode }))
        }
    }, [guarantorCode])

    useEffect(() => {
        if (companiesAgreementList?.length) {
            const company = companiesAgreementList.find(({ agreements }) => agreements.some((agr) => !agr.signed))
            const agreement = company?.agreements.find((item) => !item.signed)

            if (company && agreement) {
                setCompanyAgreement({ company, agreement })
                setIsDisabledConfirmAgreement(true)
                setIsShowModalAgreements(true)
            } else if (paramsProfileUser) {
                updateUserAction(paramsProfileUser)
            }
        }
    }, [companiesAgreementList])

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handlerSubmit}
                validate={(values) => {
                    const errors = {}

                    if (!values[FieldName.name]) {
                        errors[FieldName.name] = t('error_field_is_empty')
                    }
                    if (!values[FieldName.surname]) {
                        errors[FieldName.surname] = t('error_field_is_empty')
                    }
                    if (values[FieldName.email] && !REGEXP_EMAIL.test(values[FieldName.email])) {
                        errors[FieldName.email] = t('invalid_email')
                    }
                    if (values[FieldName.birthDate]
                        && new Date(values[FieldName.birthDate]) > new Date(maxBirthDayRestrictions)) {
                        errors[FieldName.birthDate] = true
                    }

                    return errors
                }}
                render={({ handleSubmit }) => (
                    <RegistrationUserForm
                        classes={classes}
                        countries={countries}
                        country={user?.country}
                        maxBirthDayRestrictions={maxBirthDayRestrictions}
                        isSubmitting={isSubmitting}
                        onChange={handlerChange}
                        onSubmit={handleSubmit}
                    />
                )}
            />

            <Modal
                isOpen={isShowModalAgreements && !!companyAgreement}
                classes="modal modal_small"
                onClose={() => setIsShowModalAgreements(false)}
            >
                <Modal.Body>
                    {!!companyAgreement && (
                        <CompanyAgreements
                            company={companyAgreement.company}
                            agreement={companyAgreement.agreement}
                            onChange={handlerChangeCompanyAgreements}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('ok')}
                        size="size40"
                        disabled={isDisabledConfirmAgreement}
                        onClick={handlerConfirmAgreement}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegistrationUserAction
