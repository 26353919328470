import React, { useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import {
    Container,
    PageContainer,
    ContentContainer,
    Block,
} from 'layout'
import { Auth, Registration } from 'containers'
import {
    NavBar,
    MenuNavMainPublic,
    SvgResource,
} from 'components'
import { useImgResource } from 'hooks'
import { getRandomInteger, getImageResizeSrc } from 'utils/helpers'
import style from './HomePage.module.css'

const BACKGROUND_IMG_RESOURCE = ['product_photo_1', 'product_photo_2', 'product_photo_3', 'product_photo_4', 'product_photo_5']

/**
 * Page unauthorized user
 */
const HomePage: React.FC = () => {
    const backgroundImgResource = useMemo(() => {
        const resIndex = getRandomInteger(0, BACKGROUND_IMG_RESOURCE.length - 1)
        return BACKGROUND_IMG_RESOURCE[resIndex]
    }, [])

    const { url: backgroundImgUrl } = useImgResource(backgroundImgResource)

    const backgroundImgResizeSrc = useMemo(() => {
        return backgroundImgUrl
            ? getImageResizeSrc({ src: backgroundImgUrl, width: 800, fit: 'cover' })
            : undefined
    }, [backgroundImgUrl])

    return (
        <>
            <header>
                <NavBar classes={style.navBar}>
                    <MenuNavMainPublic />
                </NavBar>
            </header>

            <Container>
                <PageContainer>
                    <ContentContainer classes={style.contentContainer}>
                        <Block classes={style.blockContent}>
                            <div className={cn(style.column, style.column_content)}>
                                <div className={style.logo}>
                                    <SvgResource
                                        isImgTag={false}
                                        resourceKey="sessia_logo"
                                        width={108}
                                        height={30}
                                    />
                                </div>
                                <div className={style.content}>
                                    <Switch>
                                        <Route exact path={APP_URL.auth} component={Auth} />
                                        <Route exact path={APP_URL.registration} component={Registration} />
                                    </Switch>
                                </div>
                            </div>
                            <div className={cn(style.column, style.column_background)}>
                                <div className={style.background}>
                                    {backgroundImgUrl && (
                                        <div
                                            className={style.image}
                                            style={{ backgroundImage: `url(${backgroundImgResizeSrc})` }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Block>
                    </ContentContainer>
                </PageContainer>
            </Container>
        </>
    )
}

export default HomePage
